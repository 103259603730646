* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.slider {
  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}

.slider-zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}
